import React from 'react';
import slugify from 'react-slugify';

import GlossaryItem from 'components/directus/glossary/item/GlossaryItem';
import { Props } from './GlossaryList.interface';

import s from './GlossaryList.scss';

export default ({ name, letters, items }: Props): JSX.Element => {
    const getItemsByLetter = (letter: string) => items.filter((item) => item.letter === letter);
    return (
        <div className={s.glossaryList__list}>
            {letters.map((letter: string) => {
                const items : any[] = getItemsByLetter(letter);

                if (items) {
                    return (
                        <div key={`glossary-list-letter-${letter}`} className={`${s.glossaryList__letter}`}>
                            <div className={s.glossaryList__heading}>
                                <h4>{letter}</h4>
                            </div>

                            <dl>
                                {getItemsByLetter(letter).map((item) => (
                                    <GlossaryItem
                                        key={`glossary-letter-${item.letter}-item${slugify(item.heading)}`}
                                        item={item}
                                    />
                                ))}
                            </dl>
                        </div>
                    );
                }

                return <></>;
            })}
        </div>
    );
};
