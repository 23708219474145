import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import slugify from 'react-slugify';
import 'react-tabs/style/react-tabs.css';

import GlossaryList from 'components/directus/glossary/list/GlossaryList';
import s from 'components/directus/glossary/Glossary.scss';
import glossaryTabHook from 'hooks/glossary/glossary-tab.hook';

export default (): JSX.Element => {
    const tabs = glossaryTabHook();
    const [ tabIndex, updateTabIndex ] = useState<number>(0);

    return (
        <>
            <div className={s.glossaryTab}>
                <Tabs selectedIndex={tabIndex} onSelect={i => updateTabIndex(i)}>
                    <TabList className={s.glossaryTab__tabs}>
                        {tabs.map(({ node }, i) => (
                            <Tab
                                key={`glossary-tab-${slugify(node.name)}`}
                                className={`${s.glossaryTab__tab} ${tabIndex === i ? s.glossaryTab__tab___active : ''}`}
                            >
                                {node.name}
                            </Tab>
                        ))}
                    </TabList>

                    {tabs.map(({ node }): JSX.Element => (
                        <TabPanel key={`glossary-tab-content-${slugify(node.name)}`}>
                            <GlossaryList name={node.name} letters={node.letters} items={node.items} />
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </>
    );
};
