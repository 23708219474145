import React from 'react';

import Markdown from 'components/common/markdown/Markdown';
import AdditionalServiceAds from 'components/directus/additional-service-ads/AdditionalServiceAds';
import { Props } from './GlossaryItem.interface';

import s from './GlossaryItem.scss';

export default ({ item }: Props): JSX.Element => {
    const { heading, content, ad_slug, is_ad_wide } = item;
    return (
        <>
            <dt className={s.glossaryItem__heading}>{heading}</dt>
            <dd className={s.glossaryItem__content}>
                <Markdown source={content} container />
            </dd>
            {item.is_ad_wide && <AdditionalServiceAds slug={ad_slug} wide={is_ad_wide} />}
        </>
    );
};
